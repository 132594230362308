import {
  Product,
  ProductConfiguration
} from "@travello/livn-service/js/product_service_pb";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { set, merge } from "lodash";

import { schema, NormalizedSchema, normalize, denormalize } from "normalizr";

const schemas = {
  Product: new schema.Entity("products")
};

type NormalizedProduct = NormalizedSchema<
  { products: { [key: string]: Product.AsObject } },
  string[]
>;

export interface State {
  products: NormalizedProduct["result"];
  entities: NormalizedProduct["entities"];
}

const initialState: State = {
  products: [],
  entities: {
    products: {}
  }
};

const productState = createSlice({
  initialState,
  reducers: {
    clearProducts(draft) {
      draft.products = [];
    },
    pushProducts(draft, action: PayloadAction<Product.AsObject[]>) {
      const p = normalize(
        action.payload.map(p => {
          return {
            ...p,
            renderedobject: ""
          };
        }),
        [schemas.Product]
      ) as NormalizedProduct;
      draft.products = [...draft.products, ...p.result];
      draft.entities = merge(draft.entities, p.entities);
    },
    setProduct(draft, action: PayloadAction<Product.AsObject>) {
      const p = normalize(action.payload, schemas.Product) as NormalizedSchema<
        { [key: string]: Product.AsObject },
        string
      >;
      draft.entities = merge(draft.entities, p.entities);
    },
    setProductConfiguration(
      draft,
      {
        payload: { id, payload }
      }: PayloadAction<{
        id: string;
        payload: ProductConfiguration.AsObject;
      }>
    ) {
      set(draft.entities.products, [id, "configuration"], payload);
    }
  },
  name: "products"
});

const getSlicedState = (state: { products: State }) =>
  state[productState.name] as State;

const getProducts = createSelector(
  [getSlicedState],
  (state: State) =>
    (denormalize(
      state.products,
      [schemas.Product],
      state.entities
    ) as Product.AsObject[]) || []
);

const findProductById = (id?: string) => {
  return createSelector([getSlicedState], (state: State) => {
    return denormalize(id, schemas.Product, state.entities) as
      | Product.AsObject
      | undefined;
  });
};

const getProductById = (id: string) =>
  createSelector([findProductById(id)], (p?: Product.AsObject) => p!);

const getProductIds = createSelector(
  [getSlicedState],
  (state: State) => state.products
);

const getProductWithSortAndFilter = ({
  filterFn = () => true,
  sortFn = undefined
}: {
  filterFn?: (product: Product.AsObject) => boolean;
  sortFn?: (productA: Product.AsObject, productB: Product.AsObject) => number;
}) =>
  createSelector([getProducts], (products: Product.AsObject[]) => {
    return products.filter(filterFn).sort(sortFn);
  });

const getProductConfigurationById = (id: string) =>
  createSelector(
    [getProductById(id)],
    (p: Product.AsObject): NonNullable<Product.AsObject["configuration"]> =>
      p.configuration ?? { displayPriority: 0, labelsList: [], tourId: 0 }
  );

const model = {
  ...productState,
  selectors: {
    getProducts,
    getProductWithSortAndFilter,
    getProductIds,
    findProductById,
    getProductById,
    getProductConfigurationById
  }
};

export default model;

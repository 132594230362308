import React, { useEffect } from "react";

import ReviewDataTable from "./ReviewDataTable";
import Layout from "./Layout";
import LayoutState from "@/modules/app/LayoutState";
import { Navigate, Outlet, Route } from "react-router-dom";

const RootView = () => {
  const { setPageTitle } = LayoutState.useContainer();
  useEffect(() => {
    setPageTitle("Pending Product Reviews");
  }, []);
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const ReviewRoutes = (
  <Route path="review" element={<RootView />}>
    <Route path="" element={<Navigate to={"/review/list"} replace />} />
    <Route path="list" element={<ReviewDataTable />} />
  </Route>
);

export default ReviewRoutes;

import useApiServices from "@/modules/app/useApiServices";

import * as productPb from "@travello/livn-service/js/product_service_pb";

import { Observable } from "rxjs";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import useAuth from "../auth/useAuth";

//#region hook
const useRepository = () => {
  const { productServiceClient: serviceClient } = useApiServices();
  const { authHeader } = useAuth();

  function findProductsByLocation$({ lat, lon }: productPb.LatLong.AsObject) {
    return new Observable<productPb.Product.AsObject>(obs => {
      const request = new productPb.LatLong();
      request.setLat(lat);
      request.setLon(lon);

      const stream = serviceClient
        .getProductsForLocation(request, authHeader)
        .on("data", response => {
          obs.next(response.toObject());
        })
        .on("status", s => {
          if (s.code === 0) {
            obs.complete();
          }
        })
        .on("error", e => {
          obs.error(e);
        })
        .on("end", () => {
          obs.complete();
        });

      return () => {
        if (stream.cancel) {
          stream.cancel();
        }
      };
    });
  }

  function findProductByIds$({
    idsList
  }: Pick<productPb.GetProductsByIdsRequest.AsObject, "idsList">) {
    return new Observable<productPb.Product.AsObject>(obs => {
      const request = new productPb.GetProductsByIdsRequest();
      idsList.forEach(id => {
        request.addIds(id);
      });

      const stream = serviceClient
        .getProductsByIds(request, authHeader)
        .on("data", response => {
          obs.next(response.toObject());
        })
        .on("status", s => {
          if (s.code === 0) {
            obs.complete();
          }
        })
        .on("error", e => {
          obs.error(e);
        })
        .on("end", () => {
          obs.complete();
        });

      return () => {
        if (stream.cancel) {
          stream.cancel();
        }
      };
    });
  }

  function configureProduct$({
    id,
    configuration: { displayPriority, labelsList, tag }
  }: Required<Pick<productPb.ProductConfigurationRequest.AsObject, "id">> & {
    configuration: Partial<productPb.ProductConfiguration.AsObject>;
  }) {
    return new Observable<void>(obs => {
      const request = new productPb.ProductConfigurationRequest();
      request.setId(id);
      const _configuration = new productPb.ProductConfiguration();
      if (displayPriority) {
        _configuration.setDisplayPriority(displayPriority);
      }
      if (labelsList) {
        _configuration.setLabelsList(labelsList);
      }
      if (tag) {
        const t = new productPb.PromoTag();
        t.setTextcolor(tag.textcolor);
        t.setText(tag.text);
        t.setBackgroundcolor(tag.backgroundcolor);
        _configuration.setTag(t);
      }
      request.setConfiguration(_configuration);

      const stream = serviceClient.configureProduct(
        request,
        authHeader,
        err => {
          if (err) {
            return obs.error(err);
          }
          obs.next();
          return obs.complete();
        }
      );

      return () => {
        if (stream.cancel) {
          stream.cancel();
        }
      };
    });
  }

  const fetchAllLabels$ = () => {
    return new Observable<string>(obs => {
      const stream = serviceClient.getAllLabels(
        new Empty(),
        authHeader,
        (error, response) => {
          if (error) {
            return obs.error(error);
          }
          response.toObject().labelsList.forEach(l => obs.next(l.label));
          obs.complete();
        }
      );
      return () => {
        if (stream.cancel) {
          stream.cancel();
        }
      };
    });
  };

  return {
    fetchAllLabels$,
    findProductByIds$,
    findProductsByLocation$,
    configureProduct$
  };
};
//#endregion

export default useRepository;

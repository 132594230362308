import React from "react";
import { IconButton } from "@rmwc/icon-button";
import { ProductReviewExtended } from "@travello/livn-service/js/product_service_pb";
import { useReviews } from "@/modules/review";

import classes from "./ReviewApprovalActionTemplate.module.scss";

const ReviewApprovalActionTemplate: React.FC<
  ProductReviewExtended.AsObject
> = ({ id }) => {
  const {
    actions: { setApproval }
  } = useReviews();

  const handleApproveClick = () => {
    setApproval(id, true);
  };

  const handleRejectClick = () => {
    setApproval(id, false);
  };

  return (
    <>
      <IconButton
        icon="check"
        onClick={handleApproveClick}
        className={classes.approveButton}
      />
      <IconButton
        icon="clear"
        onClick={handleRejectClick}
        className={classes.rejectButton}
      />
    </>
  );
};

export default ReviewApprovalActionTemplate;

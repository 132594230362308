import {
  ProductReviewExtended,
  ProductReview
} from "@travello/livn-service/js/product_service_pb";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { set } from "lodash";

export interface State {
  reviews: ProductReviewExtended.AsObject[];
}

const initialState: State = {
  reviews: []
};

const slice = createSlice({
  initialState,
  reducers: {
    clearReviews(draft) {
      draft.reviews = [];
    },
    pushReviews(
      draft,
      action: PayloadAction<ProductReviewExtended.AsObject[]>
    ) {
      draft.reviews = [
        ...draft.reviews,
        ...action.payload.map(p => {
          return {
            ...p,
            renderedobject: ""
          };
        })
      ];
    },
    approveReview(
      draft,
      {
        payload: { id, payload }
      }: PayloadAction<{ id: number; payload: ProductReview.Status }>
    ) {
      const i = draft.reviews.findIndex(p => p.id === id);
      if (i > -1) {
        set(draft.reviews, [i, "review", "status"], payload);
      }
    }
  },
  name: "reviews"
});

const getSlicedState = (state: { reviews: State }) =>
  state[slice.name] as State;

const getReviews = createSelector([getSlicedState], state => state.reviews);

const model = {
  ...slice,
  selectors: {
    getReviews,
    getReviewById: (id: number) =>
      createSelector(
        [getReviews],
        (reviews: ProductReviewExtended.AsObject[]) => {
          return reviews.find(p => p.id === id)!;
        }
      )
  }
};

export default model;

import React from "react";
import { useProduct } from "@/modules/product";
import LabelsAutoComplete from "./components/LabelsAutoComplete";

interface Props {
  productId: string;
}

const LabelsEditor: React.FC<Props> = ({ productId: id }) => {
  const {
    state: { product },
    actions: { addLabel, deleteLabelAt }
  } = useProduct(id);

  const labelsList = product?.configuration?.labelsList ?? [];

  const onAdd = (label: string) => {
    addLabel(label);
  };

  const onDelete = (label: string, index: number) => {
    deleteLabelAt(index);
  };

  return (
    <LabelsAutoComplete
      {...{
        productId: id,
        label: "Labels",
        id: "chip-input",
        fullWidth: true,
        fullWidthInput: true,
        value: labelsList,
        placeholder: "Start typing to add labels ...",
        onAdd,
        onDelete
      }}
    />
  );
};

export default LabelsEditor;

import React, { useEffect } from "react";

import useAuth from "@/modules/auth/useAuth";

import EnvironmentIndicator from "@/pages/shared/components/EnvironmentIndicator";
import Menu from "./Menu";

const SideBar: React.FC = () => {
  const { user } = useAuth();
  useEffect(() => {
    console.info(user);
  }, [user]);
  if (!user) {
    return null;
  }

  return (
    <>
      <EnvironmentIndicator />
      <Menu />
    </>
  );
};

export default SideBar;

import React, { useEffect, FormEvent } from "react";

import TextField, {
  Input,
  Props as TextFieldProps
} from "@material/react-text-field";
import MaterialIcon from "@material/react-material-icon";
import { useEffectOnce } from "react-use";
import { useSearchParams } from "react-router-dom";

interface Props extends Omit<TextFieldProps<HTMLInputElement>, "children"> {
  searchTerm: string;
  setSearchTerm: (s?: string) => void;
}

const SearchBox: React.FC<Props> = ({
  searchTerm,
  setSearchTerm,
  ...props
}) => {
  const [params, setParams] = useSearchParams({
    ...(searchTerm && {
      search: searchTerm
    })
  });

  useEffectOnce(() => {
    setSearchTerm(params.get("search") ?? undefined);
  });

  useEffect(() => {
    setParams({ search: searchTerm });
  }, [searchTerm]);

  const onChange = (e: FormEvent) => {
    const search = (e.target as any).value as string;
    if (searchTerm !== search) {
      setSearchTerm(search);
    }
  };

  return (
    <TextField
      {...{
        ...props,
        label: "Search",
        leadingIcon: <MaterialIcon icon="search" />,
        onTrailingIconSelect: () => setSearchTerm(""),
        trailingIcon: searchTerm ? (
          <MaterialIcon icon="clear" />
        ) : (
          <MaterialIcon />
        )
      }}
    >
      <Input type="text" value={searchTerm} onChange={onChange} />
    </TextField>
  );
};

export default SearchBox;

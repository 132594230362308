import React from "react";

import MaterialIcon from "@material/react-material-icon";
import Fab from "@material/react-fab/dist";
import SearchBox from "../shared/components/SearchBox";

import { DestinationsState } from "@/modules/destination";
import { useNavigate } from "react-router-dom";

const DestinationsSearchBox: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const {
    searchTerm,
    actions: { setSearchTerm }
  } = DestinationsState.useContainer();

  return (
    <>
      <SearchBox {...{ searchTerm, setSearchTerm }} />
      <Fab
        icon={<MaterialIcon hasRipple icon="add" />}
        textLabel="New"
        onClick={() => navigate("/location")}
      />
      {children}
    </>
  );
};

export default DestinationsSearchBox;

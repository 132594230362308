import React, { Suspense } from "react";

import Layout from "./Layout";

import Store from "./Store";

import { Navigate, Outlet, Route } from "react-router-dom";
import LocationRoutes from "./location/routes";
import DestinationRoutes from "./destination/routes";
import ProductRoutes from "./product/routes";
import ReviewRoutes from "./review/routes";
export interface MenuItem {
  label?: string;
  icon?: string;
  url?: string;
  items?: MenuItem[];
  disabled?: boolean;
  target?: string;
  separator?: boolean;
  style?: any;
  className?: string;
  command?(e: { originalEvent: Event; item: MenuItem }): void;
}

const MENU_ITEMS: MenuItem[] = [
  {
    label: "Map",
    url: "/destination/map",
    icon: "map"
  },
  {
    label: "Destinations",
    icon: "place",
    items: [
      {
        label: "List",
        url: "/destination/list",
        icon: "list_alt"
      },
      {
        label: "Add Destination",
        url: "/location/list",
        icon: "add"
      }
    ]
  },
  {
    label: "Products",
    icon: "attach_money",
    items: [
      {
        label: "Pending Reviews",
        url: "/review/list",
        icon: "rate_review"
      }
    ]
  }
];

export const useMenuItems = () => {
  return {
    menuItems: MENU_ITEMS
  };
};

const routes = (
  <Route
    path="/"
    element={
      <Store>
        <Layout>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </Layout>
      </Store>
    }
  >
    <Route path="/" element={<Navigate to="/destination" replace />} />
    {LocationRoutes}
    {DestinationRoutes}
    {ProductRoutes}
    {ReviewRoutes}
  </Route>
);

export default routes;

import React from "react";
import { SketchPicker, RGBColor, ColorChangeHandler } from "react-color";
import classes from "./ColorPicker.module.scss";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import { SimpleMenuSurface } from "@rmwc/menu";

interface Props {
  color: number;
  presetColors?: string[];
  onChange: (color: number) => void;
}

const rgb24ToRgb = (v: any): RGBColor => {
  const rgb24 = Number(v);
  var r = (rgb24 >> 16) & 255;
  var g = (rgb24 >> 8) & 255;
  var b = rgb24 & 255;

  return { r, g, b };
};

const hexToRgb24 = (hex: string): number => {
  return parseInt(hex.replace("#", ""), 16);
};

const useStyles = makeStyles({
  color: ({ r, g, b }: RGBColor) => {
    return {
      background: `rgb(${r},${g},${b})`
    };
  }
});

const ColorPicker: React.FC<Props> = ({ color, presetColors, onChange }) => {
  const classes2 = useStyles(rgb24ToRgb(color));

  const handleChange: ColorChangeHandler = ({ hex }) => {
    onChange(hexToRgb24(hex));
  };

  return (
    <SimpleMenuSurface
      handle={
        <div className={classes.swatch}>
          <div className={clsx(classes.color, classes2.color)} />
        </div>
      }
    >
      <SketchPicker
        presetColors={presetColors}
        disableAlpha
        color={rgb24ToRgb(color)}
        onChange={handleChange}
      />
    </SimpleMenuSurface>
  );
};

export default ColorPicker;

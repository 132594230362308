import React from "react";
import routes from "./routes";
import { ApiServicesProvider } from "@/modules/app/useApiServices";
import Theme from "./Theme";
import AuthProvider from "@/modules/auth/AuthGuard";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from "react-router-dom";

const routeObjects = createRoutesFromElements(routes);
console.log(routeObjects);
const router = createBrowserRouter(routeObjects);

const App: React.FC = () => {
  return (
    <Theme>
      <ApiServicesProvider>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ApiServicesProvider>
    </Theme>
  );
};

export default App;

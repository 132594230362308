import React from "react";
import * as productPb from "@travello/livn-service/js/product_service_pb";

import { makeStyles } from "@material-ui/core/styles";
import classNames from "clsx";

interface Props extends productPb.PromoTag.AsObject {
  className?: string;
}

const rgb24ToRgb = (v: any): string => {
  const rgb24 = Number(v);
  var r = (rgb24 >> 16) & 255;
  var g = (rgb24 >> 8) & 255;
  var b = rgb24 & 255;

  return `rgb(${r},${g},${b})`;
};

const useStyles = makeStyles({
  root: ({
    textcolor: textColor = 0xffffff,
    backgroundcolor: backgroundColor = 0xde180b
  }: Props) => {
    return {
      color: rgb24ToRgb(textColor),
      backgroundColor: rgb24ToRgb(backgroundColor)
    };
  }
});

const ProductPromoTag: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { text, className } = props;
  return <span className={classNames([classes.root, className])}>{text}</span>;
};

export default ProductPromoTag;

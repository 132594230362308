import axios from "axios";

import { GeoLocation } from "./types";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import useAuth from "../auth/useAuth";
import useConfig from "../app/useConfig";

//#region hook
const useRepository = () => {
  const { authHeader } = useAuth();
  const { __APP_CONFIG__ } = useConfig();

  function findLocations$({ query }: { query: string }) {
    return from(
      axios.get<GeoLocation[]>(
        `${__APP_CONFIG__.services?.locationData?.hostname}/location-data/locations/search`,
        {
          params: {
            q: query
          },
          headers: authHeader
        }
      )
    ).pipe(map(v => v.data));
  }

  return {
    findLocations$
  };
};
//#endregion

export default useRepository;

import useRepository from "./useRepository";
import { MapCenterContext } from "../shared/useMapCenter";
import { grpcDataBuffer } from "@/modules/utils/observable/operators";

import model from "./model";
import { useStore } from "../app/configureStore";
import { useDispatch, useSelector } from "react-redux";

import { differenceWith, isEqual, uniq } from "lodash";
import { Product } from "@travello/livn-service/js/product_service_pb";
import { useWait } from "../shared/useWait";

const getDeriveddisplayPriority = (p: { id: string }) => {
  return parseInt(p.id.replace(/\D/g, ""), 10);
};

const getProductIdAndPriority = (
  p: Product.AsObject,
  patchLegacy: boolean = false
) => {
  let displayPriority = p.configuration?.displayPriority ?? 0;
  if (patchLegacy) {
    if (
      displayPriority === -1 ||
      displayPriority === 0 ||
      displayPriority === 1
    ) {
      const deriveddisplayPriority = getDeriveddisplayPriority(p);
      displayPriority =
        displayPriority < 0 ? -deriveddisplayPriority : deriveddisplayPriority;
    }
  }
  return { id: p.id, displayPriority };
};

const prioritySortFn = (a: number, b: number) => {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
};

const idSortFn = (a: string, b: string) => {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
};

const useProducts = () => {
  const {
    state: { center }
  } = MapCenterContext.useContainer();
  const store = useStore();
  store.register(model);
  const dispatch = useDispatch();
  const { startWaiting, endWaiting, isWaiting } = useWait();

  const LOADING_MESSAGE = "fetching products";

  const { findProductsByLocation$, configureProduct$ } = useRepository();

  const clearResults = () => {
    dispatch(model.actions.clearProducts());
  };

  const fetchProductsByLocation = () => {
    if (center) {
      startWaiting(LOADING_MESSAGE);
      const [latitude, longitude] = center;
      const products$ = findProductsByLocation$({
        lat: latitude,
        lon: longitude
      }).pipe(grpcDataBuffer());
      return products$.subscribe(
        value => {
          if (value.length === 0) {
            return;
          }
          dispatch(model.actions.pushProducts(value));
        },
        null,
        () => {
          endWaiting(LOADING_MESSAGE);
        }
      );
    }
  };

  const reload = () => {
    dispatch(model.actions.clearProducts());
    return fetchProductsByLocation();
  };

  const setdisplayPriority = (id: string, displayPriority: number) => {
    const state = store.getState();
    const configuration = model.selectors.getProductConfigurationById(id)(
      state
    );

    const newConfiguration = { ...configuration, displayPriority };

    dispatch(
      model.actions.setProductConfiguration({
        id,
        payload: newConfiguration
      })
    );

    return configureProduct$({
      id,
      configuration: newConfiguration
    })
      .toPromise()
      .catch(() =>
        dispatch(
          model.actions.setProductConfiguration({
            id,
            payload: configuration
          })
        )
      );
  };

  const disableProduct = (id: string) => {
    const state = store.getState();
    const { displayPriority } = getProductIdAndPriority(
      model.selectors.getProductById(id)(state),
      true
    );
    if (displayPriority >= 0) {
      return setdisplayPriority(id, -displayPriority);
    }
  };

  const enabledProducts = useSelector(
    model.selectors.getProductWithSortAndFilter({
      filterFn: p => {
        return (p.configuration?.displayPriority ?? -1) > -1;
      },
      sortFn: (a, b) => {
        const pA = getProductIdAndPriority(a);
        const pB = getProductIdAndPriority(b);

        return (
          prioritySortFn(pA.displayPriority, pB.displayPriority) ||
          idSortFn(pA.id, pB.id)
        );
      }
    })
  );

  const enableProduct = (id: string, index: number) => {
    const state = store.getState();
    let { displayPriority } = getProductIdAndPriority(
      model.selectors.getProductById(id)(state),
      true
    );
    if (displayPriority <= 0) {
      displayPriority = -displayPriority;
    }
    const prevIndex = enabledProducts.findIndex(p => p.id === id);

    // snapshot
    const existing = enabledProducts
      .slice(0)
      .map(p => getProductIdAndPriority(p));

    // insert
    let current = enabledProducts
      .slice(0)
      .map(p => getProductIdAndPriority(p, true));
    if (prevIndex >= 0) {
      current.splice(prevIndex, 1);
    }
    current.splice(index, 0, { id, displayPriority });

    // re-order
    let newPriorities = current.slice(0).map(p => p.displayPriority);

    if (newPriorities.length !== uniq(newPriorities).length) {
      // dedupe if something went wrong
      newPriorities = current.slice(0).map(p => getDeriveddisplayPriority(p));
    }

    newPriorities.sort(prioritySortFn);

    const currentWithNewPriority = current.slice(0).map((p, i) => ({
      ...p,
      displayPriority: newPriorities[i]
    }));

    // send the diff
    return Promise.all(
      differenceWith(
        currentWithNewPriority,
        existing,
        isEqual
      ).map(({ id, displayPriority }) =>
        setdisplayPriority(id, displayPriority)
      )
    );
  };

  return {
    state: {
      isLoading: isWaiting(LOADING_MESSAGE),
      enabledProducts,
      disabledProducts: useSelector(
        model.selectors.getProductWithSortAndFilter({
          filterFn: p => {
            return (p.configuration?.displayPriority ?? -1) < 0;
          }
        })
      )
    },
    actions: {
      loadProducts: reload,
      clearProducts: clearResults,
      disableProduct,
      enableProduct
    }
  };
};

export default useProducts;

import React from "react";

import { DestinationsState } from "@/modules/destination";
import { AuthGuard } from "@/modules/auth/AuthGuard";

import configureStore from "@/modules/app/configureStore";
import { Provider } from "react-redux";

const store = configureStore({ reducer: state => state });

const Store: React.FC = ({ children }) => {
  return (
    <AuthGuard>
      <Provider store={store}>
        <DestinationsState.Provider>{children}</DestinationsState.Provider>
      </Provider>
    </AuthGuard>
  );
};

export default Store;

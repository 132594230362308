import React, { memo } from "react";

import {
  List,
  SimpleListItem,
  CollapsibleList,
  ListDivider,
  ListGroup
} from "@rmwc/list";
import { useMenuItems } from "./routes";
import useAuth from "@/modules/auth/useAuth";
import { MenuItem } from "@/pages/routes";
import { Link, useLocation } from "react-router-dom";

interface MenuItemsListProps {
  menuItems: MenuItem[];
  parentKey?: string;
}

const MenuItemsList: React.FC<MenuItemsListProps> = memo(
  ({ menuItems, parentKey }) => {
    const currentRoute = useLocation();

    return (
      <>
        {menuItems.map(m => {
          const active = currentRoute.pathname === m.url;

          const key = [parentKey, m.label].join("-");

          if (m.items && m.items.length > 0) {
            return (
              <CollapsibleList
                startOpen
                key={key}
                handle={
                  <SimpleListItem
                    text={m.label}
                    graphic={m.icon}
                    metaIcon="chevron_right"
                  />
                }
              >
                <MenuItemsList menuItems={m.items} parentKey={key} />
              </CollapsibleList>
            );
          }
          if (m.url) {
            return (
              <Link key={key} to={m.url}>
                <SimpleListItem
                  selected={active}
                  graphic={m.icon}
                  text={m.label}
                />
              </Link>
            );
          }
          return (
            <SimpleListItem
              selected={active}
              key={key}
              graphic={m.icon}
              text={m.label}
            />
          );
        })}
      </>
    );
  }
);

MenuItemsList.displayName = "MenuItemsList";

const Menu = () => {
  const { menuItems } = useMenuItems();
  const { logout, isIFrame } = useAuth();

  return (
    <ListGroup>
      <List>
        <MenuItemsList {...{ menuItems }} />
        <ListDivider />
      </List>
      {isIFrame ? null : (
        <List>
          <SimpleListItem
            graphic="power_settings_new"
            text="Logout"
            onClick={() => logout()}
          />
        </List>
      )}
    </ListGroup>
  );
};

export default Menu;

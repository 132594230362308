import useApiServices from "@/modules/app/useApiServices";

import {
  GetProductReviewsRequest,
  ProductReview,
  ProductReviewExtended,
  SetProductReviewRequest
} from "@travello/livn-service/js/product_service_pb";

import { Observable } from "rxjs";
import useAuth from "../auth/useAuth";

//#region hook
const useRepository = () => {
  const { productServiceClient: serviceClient } = useApiServices();
  const { authHeader } = useAuth();

  function fetchReviews$({
    status = ProductReview.Status.PENDING
  }: Partial<GetProductReviewsRequest.AsObject>) {
    return new Observable<ProductReviewExtended.AsObject>(obs => {
      const request = new GetProductReviewsRequest();
      request.setStatus(status);

      const stream = serviceClient
        .getProductReviews(request, authHeader)
        .on("data", response => {
          obs.next(response.toObject());
        })
        .on("status", s => {
          if (s.code === 0) {
            obs.complete();
          }
        })
        .on("error", () => {
          obs.error();
        });

      return () => {
        if (stream.cancel) {
          stream.cancel();
        }
      };
    });
  }

  function updateReview$({
    userId,
    productId,
    review
  }: Required<SetProductReviewRequest.AsObject>) {
    return new Observable<void>(obs => {
      const request = new SetProductReviewRequest();
      request.setProductId(productId);
      request.setUserId(userId);
      const _review = new ProductReview();
      if (review) {
        _review.setStatus(review.status);
        _review.setRating(review.rating);
        _review.setReviewText(review.reviewText);
        _review.setReviewTitle(review.reviewTitle);
      }
      request.setReview(_review);

      const stream = serviceClient.setProductReview(
        request,
        authHeader,
        err => {
          if (err) {
            return obs.error(err);
          }
          obs.next();
          return obs.complete();
        }
      );

      return () => {
        if (stream.cancel) {
          stream.cancel();
        }
      };
    });
  }

  return {
    fetchReviews$,
    updateReview$
  };
};
//#endregion

export default useRepository;

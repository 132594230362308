import React from "react";

import { Product } from "@travello/livn-service/js/product_service_pb";
import StructuredDetailView from "@/pages/shared/components/StructuredDetailView";

const ProductDetail: React.FC<Product.AsObject> = ({
  renderedObject,
  ...p
}) => {
  return <StructuredDetailView<Product.AsObject> {...p} />;
};

export default ProductDetail;

import React from "react";
import { GeoLocation } from "@/modules/location/types";
import DataTableLinkTemplate from "@/pages/shared/components/DataTableLinkTemplate";

const WikipediaLinkTemplate: React.FC<GeoLocation> = ({ wikipediaLink }) => {
  return (
    <DataTableLinkTemplate
      href={wikipediaLink || undefined}
      target="wikipediaTarget"
    >
      {wikipediaLink}
    </DataTableLinkTemplate>
  );
};

export default WikipediaLinkTemplate;

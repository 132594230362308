import { useEffect } from "react";
import useSearchTerm from "../shared/useSearchTerm";
import useRepository from "./useRepository";
import { useList } from "react-use";
import { Destination } from "@travello/destination-service/js/destination_service_pb";
import { grpcDataBuffer } from "@/modules/utils/observable/operators";
//#region state shape
export interface State {
  searchTerm: string;
}

const initState: State = {
  searchTerm: ""
};
//#endregion

//#region hook
const useDestinations = ({ searchTerm: initSearchTerm } = initState) => {
  const [
    destinationsAll,
    { clear: clearDestinationsAll, push: pushDestinationsAll }
  ] = useList<Destination.AsObject>([]);

  const [
    destinations,
    { clear: clearDestinations, push: pushDestinations }
  ] = useList<Destination.AsObject>([]);

  const {
    findDestinations$,
    getAllDestinations$,
    getDestination$,
    updateDestination$,
    createDestination$,
    deleteDestination$
  } = useRepository();
  const { searchTerm, debouncedSearchTerm, setSearchTerm } = useSearchTerm({
    searchTerm: initSearchTerm
  });

  const reloadAllData = () => {
    clearDestinationsAll();
    return getAllDestinations$()
      .pipe(grpcDataBuffer())
      .subscribe(value => {
        pushDestinationsAll(...value);
      });
  };

  const reloadData = () => {
    clearDestinations();

    const d$ = debouncedSearchTerm
      ? findDestinations$({ query: debouncedSearchTerm })
      : getAllDestinations$();

    return d$.pipe(grpcDataBuffer()).subscribe(value => {
      pushDestinations(...value);
    });
  };

  useEffect(() => {
    const s = reloadAllData(); // this only load once on load
    return () => {
      s.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const s = reloadData();
    return () => {
      s.unsubscribe();
    };
  }, [debouncedSearchTerm]);

  return {
    destinationsAll,
    destinations,
    searchTerm,
    actions: {
      setSearchTerm,
      getDestination$,
      updateDestination$,
      createDestination$,
      deleteDestination$,
      reload() {
        reloadData();
        reloadAllData();
      }
    }
  };
};
//#endregion

export default useDestinations;

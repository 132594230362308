import React from "react";

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter
} from "@material/react-dialog";

import { Destination } from "@travello/destination-service/js/destination_service_pb";
import { DestinationsState } from "@/modules/destination";

import DestinationDetailForm, {
  DestinationDetailFormOnSubmit
} from "./components/DestinationDetailForm";

import DataObjectParser from "dataobject-parser";
import { useNavigate } from "react-router-dom";

const CreateDestination: React.FC<Partial<Destination.AsObject>> = ({
  children,
  ...initDetail
}) => {
  const navigate = useNavigate();

  const {
    actions: { createDestination$, reload, setSearchTerm }
  } = DestinationsState.useContainer();

  const onHide = () => navigate(-1);

  const onSubmit: DestinationDetailFormOnSubmit = async v => {
    setSearchTerm("");

    await createDestination$(v).toPromise();
    reload();
    const query = DataObjectParser.untranspose({
      search: v.name,
      center: [v.referencePoint?.lat, v.referencePoint?.lon]
    });

    const searchParams = new URLSearchParams(query);

    navigate({
      pathname: "/destination/map",
      search: searchParams.toString()
    });
  };

  return (
    <Dialog onClose={onHide} open className="mdc-dialog--scrollable">
      <DialogTitle>Create New Destination</DialogTitle>
      <DestinationDetailForm
        {...{
          ...{
            id: "",
            name: "",
            geonameId: 0,
            imageUrl: "",
            referencePoint: {
              lat: 0,
              lon: 0
            },
            ...(initDetail as any)
          },
          onSubmit,
          submitButtonLabel: "Create"
        }}
        ContentContainer={DialogContent}
        FooterContainer={DialogFooter}
      />
    </Dialog>
  );
};

export default CreateDestination;

import React from "react";

import {
  DataTable,
  DataTableContent,
  DataTableBody,
  DataTableRow,
  DataTableCell
} from "@rmwc/data-table";

import classes from "./DetailView.module.scss";

import { sortBy, isArray, isObject, isString } from "lodash";

const isDataEntry = (v: any) => isArray(v) && v.length === 2 && isString(v[0]);

const StructedDataRow: React.FC<{ k: string; v: unknown }> = ({ k, v }) => {
  if (v === undefined) {
    return null;
  }
  return (
    <DataTableRow>
      <DataTableCell>{k}</DataTableCell>
      <DataTableCell>
        <StructredDataTable data={v} />
      </DataTableCell>
    </DataTableRow>
  );
};

const StructredDataTable: React.FC<{ data: any }> = ({ data }) => {
  if (isArray(data)) {
    if (data.every(isDataEntry)) {
      return (
        // Object.entries array
        <DataTable>
          <DataTableContent>
            <DataTableBody>
              {data.map(([k, v]) => (
                <StructedDataRow key={k} {...{ k, v }} />
              ))}
            </DataTableBody>
          </DataTableContent>
        </DataTable>
      );
    } else {
      return (
        <>
          {data.map((v, k) => (
            <div key={`row-${k}`}>
              <StructredDataTable data={v} />
            </div>
          ))}
        </>
      );
    }
  }

  if (isObject(data)) {
    const _data = sortBy(Object.entries(data), 0);
    return <StructredDataTable data={_data} />;
  }
  return <>{`${data}`}</>;
};

const StructuredDetailView = <P extends {}>(p: Partial<P>) => {
  return (
    <div className={classes.dataTable}>
      <StructredDataTable data={p} />
    </div>
  );
};

export default StructuredDetailView;

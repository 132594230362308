import React from "react";
import { Outlet, Route, useNavigate, useParams } from "react-router-dom";
import ViewProduct from "./ViewProduct/ViewProduct";

const RootView = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const IdRoute = () => {
  const { id = "" } = useParams();

  const navigate = useNavigate();

  return <ViewProduct {...{ id, onCloseClick: () => navigate(-1) }} />;
};

const ProductRoutes = (
  <Route path="product" element={<RootView />}>
    <Route path=":id" element={<IdRoute />} />
  </Route>
);

export default ProductRoutes;

import React from "react";
import { useProduct } from "@/modules/product";
import * as productPb from "@travello/livn-service/js/product_service_pb";
import ColorPicker from "./components/ColorPicker";

import classes from "./PromoTagEditor.module.scss";
import ProductPromoTag from "./components/ProductPromoTag";
import { TextField } from "@rmwc/textfield";
import useForm from "react-hook-form";
import { Button } from "@material/react-button";

import * as yup from "yup";

const ValidationSchema = yup.object().shape<productPb.PromoTag.AsObject>({
  text: yup.string().max(24),
  textcolor: yup.number(),
  backgroundcolor: yup.number()
});

interface Props {
  productId: string;
}

const PromoTagEditor: React.FC<Props> = ({ productId: id }) => {
  const {
    state: { product },
    actions: { setPromoTag }
  } = useProduct(id);

  const {
    watch,
    register,
    formState: { dirty, isSubmitting },
    handleSubmit,
    setValue,
    reset
  } = useForm<productPb.PromoTag.AsObject>({
    validationSchema: ValidationSchema,
    defaultValues: product?.configuration?.tag ?? {
      text: "",
      textcolor: 0,
      backgroundcolor: 0
    }
  });

  const onSubmit = (data: productPb.PromoTag.AsObject) => {
    setPromoTag(data.text, data.textcolor, data.backgroundcolor).then(() => {
      reset(data);
    });
  };

  const values = watch();

  return (
    <>
      <label>Promo Tag</label>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="mdc-data-table__table">
          <tbody className="mdc-data-table__content">
            <tr className="mdc-data-table__row">
              <th
                className="mdc-data-table__header-cell"
                style={{ width: "10em" }}
              >
                Text
              </th>
              <td className="mdc-data-table__cell" style={{ width: "15em" }}>
                <TextField
                  maxLength={24}
                  outlined
                  name="text"
                  inputRef={register}
                />
              </td>
              <td rowSpan={2} className="mdc-data-table__cell">
                <div className={classes.preview}>
                  {!!values.text && (
                    <ProductPromoTag {...values} className={classes.promoTag} />
                  )}
                </div>
              </td>
            </tr>
            <tr className="mdc-data-table__row">
              <th className="mdc-data-table__header-cell">Text Colour</th>
              <td className="mdc-data-table__cell">
                <ColorPicker
                  presetColors={[
                    "#F8C81C",
                    "#DE180B",
                    "#B063F3",
                    "#FF8459",
                    "#0072BE",
                    "#76BA16",
                    "#10ABE4",
                    "#000000",
                    "#ffffff"
                  ]}
                  color={values.textcolor}
                  onChange={color => setValue("textcolor", color)}
                />
                <input type="hidden" name="textcolor" ref={register} />
              </td>
            </tr>
            <tr className="mdc-data-table__row">
              <th className="mdc-data-table__header-cell">Background Colour</th>
              <td className="mdc-data-table__cell">
                <ColorPicker
                  presetColors={[
                    "#F8C81C",
                    "#DE180B",
                    "#B063F3",
                    "#FF8459",
                    "#0072BE",
                    "#76BA16",
                    "#10ABE4",
                    "#000000",
                    "#ffffff"
                  ]}
                  color={values.backgroundcolor}
                  onChange={color => setValue("backgroundcolor", color)}
                />
                <input type="hidden" name="backgroundcolor" ref={register} />
              </td>
              <td className="mdc-data-table__cell">
                {dirty && (
                  <Button raised type="submit" disabled={isSubmitting}>
                    Save Promo Tag
                  </Button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </>
  );
};

export default PromoTagEditor;

import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import classes from "./Theme.module.scss";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#66ddff",
      main: classes.primary,
      dark: "#007cb2",
      contrastText: classes.onPrimary
    },
    secondary: {
      light: "#54a7d1",
      main: classes.secondary,
      dark: "#004c71",
      contrastText: classes.onSecondary
    }
  }
});

const Theme: React.FC = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;

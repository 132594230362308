import React, { useContext } from "react";

import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

const { bugsnag: bugSnagConfig } = window.__APP_CONFIG__;
const { version, vcs_ref } = window.__BUILD_INFO__;

const bugsnagClient = bugsnag({
  ...bugSnagConfig,
  notifyReleaseStages: ["production", "staging"],
  appVersion: version,
  revision: vcs_ref
});
bugsnagClient.use(bugsnagReact, React);
export const ErrorBoundary = bugsnagClient.getPlugin("react");

export const MetricsContext = React.createContext({
  bugsnagClient
});

const useMetrics = () => {
  return useContext(MetricsContext);
};

export default useMetrics;

import React, { useState, useEffect } from "react";

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter
} from "@material/react-dialog";
import Button from "@material/react-button";

import { Destination } from "@travello/destination-service/js/destination_service_pb";
import { DestinationsState } from "@/modules/destination";

import DestinationDetailForm, {
  DestinationDetailFormOnSubmit
} from "./components/DestinationDetailForm";
import { Subscription } from "rxjs";
import { useNavigate } from "react-router-dom";

interface Props {
  id?: string;
}

const ViewDestination: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate();

  const {
    destinations,
    actions: { getDestination$, updateDestination$, deleteDestination$, reload }
  } = DestinationsState.useContainer();

  const [detail, setDetail] = useState<Destination.AsObject | null>(null);

  useEffect(() => {
    let p: Subscription | undefined;
    if (id) {
      const _detail = destinations.find(v => v.id === id);

      if (_detail) {
        setDetail(_detail);
      } else {
        p = getDestination$({ id }).subscribe(value => setDetail(value));
      }
    } else {
      setDetail(null);
    }

    return function cleanup() {
      if (p) {
        p.unsubscribe();
      }
    };
  }, [id]);

  if (!id || !detail) {
    return null;
  }

  const onHide = () => {
    navigate(-1);
  };

  const onSubmit: DestinationDetailFormOnSubmit = async v => {
    await updateDestination$(v).toPromise();
    reload();
    onHide();
  };

  const onDeleteClick = () => {
    if (window.confirm("Delete this destination?")) {
      deleteDestination$({ id }).subscribe(() => {
        reload();
        onHide();
      });
    }
  };

  return (
    <Dialog onClose={onHide} open className="mdc-dialog--scrollable">
      <DialogTitle tag="div">{detail.name}</DialogTitle>
      <DestinationDetailForm
        {...{
          ...detail,
          onSubmit,
          submitButtonLabel: "Save"
        }}
        disableButtonOnClean
        ContentContainer={DialogContent}
        FooterContainer={DialogFooter}
      >
        <Button
          raised
          type="button"
          className="mdc-dialog__button mdc-button--danger"
          onClick={onDeleteClick}
        >
          Delete
        </Button>
      </DestinationDetailForm>
    </Dialog>
  );
};

export default ViewDestination;

import { useEffect } from "react";
import { useList } from "react-use";

import { GeoLocation } from "./types";
import useSearchTerm from "../shared/useSearchTerm";
import useRepository from "./useRepository";
import { Subscription } from "rxjs";

//#region state shape
export interface State {
  searchTerm: string;
  locations: GeoLocation[];
}

const initState: State = {
  searchTerm: "",
  locations: []
};
//#endregion

//#region hook
const useLocations = ({
  searchTerm: initSearchTerm,
  locations: initLocations
} = initState) => {
  const { searchTerm, debouncedSearchTerm, setSearchTerm } = useSearchTerm({
    searchTerm: initSearchTerm
  });
  const [results, { set: setResults }] = useList(initLocations);
  const { findLocations$ } = useRepository();

  useEffect(() => {
    let p: Subscription | undefined;
    if (debouncedSearchTerm) {
      p = findLocations$({ query: debouncedSearchTerm }).subscribe(setResults);
    }
    return function cleanup() {
      if (p) {
        p.unsubscribe();
      }
    };
  }, [debouncedSearchTerm]);

  return {
    locations: results,
    searchTerm,
    actions: {
      setSearchTerm
    }
  };
};
//#endregion

export default useLocations;

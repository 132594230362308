import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { ErrorBoundary } from "@/modules/app/useMetrics";
import App from "./pages/App";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "@rmwc/list/collapsible-list.css";
import "@rmwc/data-table/data-table.css";
import "leaflet/dist/leaflet.css";
import "@/index.scss";
import { Waiter } from "./modules/shared/useWait";

ReactDOM.render(
  <ErrorBoundary>
    <Waiter>
      <App />
    </Waiter>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

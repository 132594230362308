import React, { useRef, useMemo } from "react";

import { uniq } from "lodash";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { LocationsState } from "@/modules/location";
import WikipediaLinkTemplate from "./components/WikipediaLinkTemplate";
import LocationGeoDetail from "./components/LocationGeoDetail";
import { GeoLocation } from "@/modules/location/types";
import CreateDestinationActionTemplate from "./components/CreateDestinationActionTemplate";
import { useSetState } from "react-use";
import { MultiSelect } from "primereact/multiselect";

interface SelectItem {
  label: string;
  value: string;
}

const getSelectItem = (v: string): SelectItem => {
  return {
    label: v,
    value: v
  };
};

const LocationPage: React.FC = () => {
  const { locations } = LocationsState.useContainer();

  const dtRef = useRef<any>();

  const [{ expandedRows, countries, types }, setState] = useSetState<any>({
    expandedRows: null,
    countries: null,
    types: null
  });

  const filterOptions: {
    types: SelectItem[];
    countries: SelectItem[];
  } = useMemo(() => {
    return {
      types: uniq(locations.map(v => v.type)).map(getSelectItem),
      countries: uniq(locations.map(v => v.country)).map(getSelectItem)
    };
  }, [locations]);

  const onTypeChange = (event: any) => {
    dtRef.current.filter(event.value, "type", "in");
    setState({ types: event.value });
  };

  const onCountryChange = (event: any) => {
    dtRef.current.filter(event.value, "country", "in");
    setState({ countries: event.value });
  };

  return (
    <DataTable
      ref={dtRef}
      expandedRows={expandedRows}
      onRowToggle={e => setState({ expandedRows: e.data })}
      value={locations}
      sortMode="multiple"
      paginator
      rows={20}
      rowsPerPageOptions={[20, 50, 100]}
      rowExpansionTemplate={(d: GeoLocation) => <LocationGeoDetail {...d} />}
    >
      <Column expander style={{ width: "3em" }} />
      <Column
        key="name"
        field="name"
        header="Name"
        style={{ width: "20%" }}
        sortable
        filter
        filterMatchMode="contains"
      />
      <Column
        key="country"
        field="country"
        header="Country"
        style={{ width: "10%" }}
        sortable
        filter
        filterElement={
          <MultiSelect
            style={{ width: "100%" }}
            value={countries}
            options={filterOptions.countries}
            onChange={onCountryChange}
          />
        }
      />
      <Column
        key="type"
        field="type"
        header="Type"
        sortable
        filter
        filterElement={
          <MultiSelect
            style={{ width: "100%" }}
            value={types}
            options={filterOptions.types}
            onChange={onTypeChange}
          />
        }
      />
      <Column
        key="wikipediaLink"
        field="wikipediaLink"
        header="Wikipedia"
        body={(p: any) => <WikipediaLinkTemplate {...p} />}
      />
      <Column
        style={{ width: "15em" }}
        key="createDestination"
        header=""
        body={(p: any) => <CreateDestinationActionTemplate {...p} />}
      />
    </DataTable>
  );
};

export default LocationPage;

import React, { useCallback } from "react";
import { useSetState } from "react-use";
import { createContainer } from "unstated-next";

const anyWaiting = (waiters: string[]) => waiters.length > 0;

const isWaiting = (waiters: string[], waiter: string) =>
  waiters.includes(waiter);

const startWaiting = (waiters: string[], waiter: string) => {
  if (isWaiting(waiters, waiter)) return waiters;
  return [...waiters, waiter];
};
const endWaiting = (waiters: string[], waiter: string) => {
  return waiters.filter(w => w !== waiter);
};

interface State {
  waiters: string[];
}

const DEFAULT_STATE: State = {
  waiters: []
};

const useWaitState = (initState = DEFAULT_STATE) => {
  const [{ waiters }, setState] = useSetState(initState);

  return {
    waiters,
    anyWaiting: useCallback(() => anyWaiting(waiters), [waiters]),
    isWaiting: useCallback((waiter: string) => isWaiting(waiters, waiter), [
      waiters
    ]),
    startWaiting: (waiter: string) =>
      setState({ waiters: startWaiting(waiters, waiter) }),
    endWaiting: (waiter: string) =>
      setState({ waiters: endWaiting(waiters, waiter) })
  };
};

const WaiterState = createContainer(useWaitState);

export default WaiterState;

export const Waiter = WaiterState.Provider as React.FC;
export const useWait = WaiterState.useContainer;

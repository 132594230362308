import React, { useMemo, memo, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton
} from "@rmwc/dialog";
import classNames from "clsx";
import ProductDetail from "./components/ProductDetail";
import classes from "../ProductList.module.scss";
import { useProduct } from "@/modules/product";
import BusyIndicator from "@/pages/shared/components/BusyIndicator";
import { Product } from "@travello/livn-service/js/product_service_pb";

import LabelsEditor from "./LabelsEditor";
import PromoTagEditor from "./PromoTagEditor";
import { CollapsibleList, SimpleListItem } from "@rmwc/list";

interface Props {
  id: string;
  onCloseClick: (item?: Product.AsObject) => void;
}

const ViewProduct: React.FC<Props> = memo(({ id, onCloseClick }) => {
  const {
    state: { product, isLoading },
    actions: { loadProduct }
  } = useProduct(id);

  useEffect(() => {
    if (!product && id) {
      const s = loadProduct();
      return () => {
        s.unsubscribe();
      };
    }
  }, [product]);
  return useMemo(() => {
    return (
      <Dialog
        className={classNames(classes.dialog, "mdc-dialog--scrollable")}
        open={true}
        onClose={() => onCloseClick(product)}
      >
        <DialogContent>
          {isLoading && <BusyIndicator />}
          {!!product && (
            <>
              <LabelsEditor productId={id} />
              <PromoTagEditor productId={id} />
              <CollapsibleList
                startOpen
                handle={
                  <SimpleListItem
                    graphic="info_outline"
                    text="More Info"
                    metaIcon="chevron_right"
                  />
                }
              >
                <ProductDetail {...product} />
              </CollapsibleList>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <DialogButton dense action="close">
            Close
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }, [product]);
});

export default ViewProduct;

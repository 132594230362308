import { useSetState } from "react-use";
import { createContainer } from "unstated-next";

interface State {
  drawerOpen: boolean;
  pageTitle: string;
}

const DEFAULT_STATE: State = {
  drawerOpen: true,
  pageTitle: ""
};

const useLayoutState = (initState = DEFAULT_STATE) => {
  const [state, setState] = useSetState(initState);

  const toggleDrawer = (open: boolean = !state.drawerOpen) => {
    setState({ drawerOpen: open });
  };

  const setPageTitle = (title: string) => {
    setState({ pageTitle: title });
  };

  return {
    ...state,
    toggleDrawer,
    setPageTitle
  };
};

const LayoutState = createContainer(useLayoutState);

export default LayoutState;

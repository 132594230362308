const { __APP_CONFIG__, __GRPCWEB_DEVTOOLS__ } = window;

const useConfig = () => {
  return {
    __APP_CONFIG__,
    __GRPCWEB_DEVTOOLS__
  };
};

export default useConfig;

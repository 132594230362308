import useRepository from "./useRepository";

import model from "./model";
import { useStore } from "../app/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { toArray } from "rxjs/operators";
import { uniq } from "lodash";
import { ProductConfiguration } from "@travello/livn-service/js/product_service_pb";
import { useWait } from "../shared/useWait";

const useProduct = (id: string) => {
  const store = useStore();
  store.register(model);
  const dispatch = useDispatch();
  const { startWaiting, endWaiting, isWaiting } = useWait();

  const product = useSelector(model.selectors.findProductById(id));

  const {
    findProductByIds$,
    configureProduct$,
    fetchAllLabels$
  } = useRepository();

  const LOADING_MESSAGE = `fetching product ${id}`;
  const isLoading = isWaiting(LOADING_MESSAGE);

  // not working. as livn api /multi isn't working as expected
  const loadProduct = () => {
    startWaiting(LOADING_MESSAGE);
    return findProductByIds$({ idsList: [id] }).subscribe(
      value => {
        dispatch(model.actions.setProduct(value));
      },
      null,
      () => {
        endWaiting(LOADING_MESSAGE);
      }
    );
  };

  const patchAndSubmitConfig = (
    patchFn: (
      old: ProductConfiguration.AsObject
    ) => ProductConfiguration.AsObject
  ) => {
    const state = store.getState();
    const configuration = model.selectors.getProductConfigurationById(id)(
      state
    );

    const newConfiguration = patchFn(configuration);

    dispatch(
      model.actions.setProductConfiguration({
        id,
        payload: newConfiguration
      })
    );

    return configureProduct$({
      id,
      configuration: newConfiguration
    })
      .toPromise()
      .catch(() =>
        dispatch(
          model.actions.setProductConfiguration({
            id,
            payload: configuration
          })
        )
      );
  };

  const setLabelsList = (labelsList: string[]) => {
    return patchAndSubmitConfig(configuration => ({
      ...configuration,
      labelsList
    }));
  };

  const labelsList = product?.configuration?.labelsList ?? [];

  const addLabel = (label: string) => {
    setLabelsList(uniq([...labelsList, label]));
  };

  const deleteLabelAt = (index: number) => {
    const newlist = [...labelsList];
    newlist.splice(index, 1);
    setLabelsList(newlist);
  };

  const setPromoTag = (
    text: string = "",
    textColor: number = 0,
    backgroundColor: number = 0
  ) => {
    return patchAndSubmitConfig(configuration => ({
      ...configuration,
      tag: {
        text,
        textcolor: textColor,
        backgroundcolor: backgroundColor
      }
    }));
  };

  return {
    state: { product, isLoading },
    actions: {
      loadProduct,
      addLabel,
      deleteLabelAt,
      setPromoTag,
      fetchAllLabels() {
        return fetchAllLabels$()
          .pipe(toArray())
          .toPromise();
      }
    }
  };
};

export default useProduct;

import React, { useEffect, Suspense } from "react";

import DataObjectParser from "dataobject-parser";

import { Destination } from "@travello/destination-service/js/destination_service_pb";
import DestinationsDataTable from "./DestinationsDataTable";
import Layout from "./Layout";
import ViewDestination from "./ViewDestination";
import CreateDestination from "./CreateDestination";
import LayoutState from "@/modules/app/LayoutState";
import { MapCenterContext } from "@/modules/shared/useMapCenter";
import {
  Navigate,
  Outlet,
  Route,
  useParams,
  useSearchParams
} from "react-router-dom";

const DestinationMap = React.lazy(() =>
  import("./DestinationMap/DestinationMap")
);

const RootView = () => {
  const { setPageTitle } = LayoutState.useContainer();
  useEffect(() => {
    setPageTitle("Destinations");
  }, []);
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const IdRoute = () => {
  const { id } = useParams();

  return <ViewDestination {...{ id }} />;
};

const CreateRoute = () => {
  const [params] = useSearchParams();

  const initDetail = DataObjectParser.transpose<Partial<Destination.AsObject>>(
    Object.fromEntries(params)
  ).data();

  return <CreateDestination {...initDetail} />;
};

const DestinationRoutes = (
  <Route path="destination" element={<RootView />}>
    <Route path="" element={<Navigate to={"/destination/map"} replace />} />
    <Route
      path="list"
      element={
        <>
          <DestinationsDataTable />
          <Outlet />
        </>
      }
    >
      <Route path="" element={<></>} />
      <Route path=":id" element={<IdRoute />} />
    </Route>
    <Route
      path="map"
      element={
        <MapCenterContext.Provider>
          <Suspense fallback={null}>
            <DestinationMap />
          </Suspense>
          <Outlet />
        </MapCenterContext.Provider>
      }
    >
      <Route path="" element={<></>} />
      <Route path=":id" element={<IdRoute />} />
    </Route>
    <Route path="create" element={<CreateRoute />} />
  </Route>
);

export default DestinationRoutes;

import {
  DestinationSearchRequest,
  LatLong,
  Destinations,
  Destination,
  DestinationRef
} from "@travello/destination-service/js/destination_service_pb";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";

import useApiServices from "@/modules/app/useApiServices";

import { Observable } from "rxjs";
import useAuth from "../auth/useAuth";

const deriveDestinationFromDestinations = (d: Destinations) => {
  return d.getDestinationList().map(v => v.toObject());
};

//#region hook
const useRepository = () => {
  const { destinationServiceClient: serviceClient } = useApiServices();
  const { authHeader } = useAuth();

  function getAllDestinations$() {
    return new Observable<Destination.AsObject>(obs => {
      serviceClient.getAllDestinations(
        new Empty(),
        authHeader,
        (error, response) => {
          if (error) {
            return obs.error(error);
          }
          if (response) {
            deriveDestinationFromDestinations(response).map(obs.next.bind(obs));
            return obs.complete();
          }
        }
      );
    });
  }

  function findDestinations$({
    query,
    location
  }: DestinationSearchRequest.AsObject) {
    return new Observable<Destination.AsObject>(obs => {
      const request = new DestinationSearchRequest();
      if (query) {
        request.setQuery(query);
      }
      if (location) {
        const l = new LatLong();
        l.setLat(Number(location.lat));
        l.setLon(Number(location.lon));
        request.setLocation(l);
      }

      serviceClient.findDestinations(request, authHeader, (error, response) => {
        if (error) {
          return obs.error(error);
        }
        if (response) {
          deriveDestinationFromDestinations(response).map(obs.next.bind(obs));
          return obs.complete();
        }
      });
    });
  }

  function createDestination$({
    name,
    referencePoint,
    imageUrl,
    geonameId
  }: Destination.AsObject) {
    return new Observable<void>(obs => {
      const request = new Destination();
      request.setName(name);

      if (referencePoint) {
        const l = new LatLong();
        l.setLat(Number(referencePoint.lat));
        l.setLon(Number(referencePoint.lon));
        request.setReferencePoint(l);
      }

      request.setImageUrl(imageUrl);
      request.setGeonameId(Number(geonameId));

      serviceClient.createDestination(request, authHeader, error => {
        if (error) {
          return obs.error(error);
        }
        obs.next();
        return obs.complete();
      });
    });
  }

  function getDestination$({ id }: DestinationRef.AsObject) {
    return new Observable<Destination.AsObject>(obs => {
      const request = new DestinationRef();
      request.setId(id);

      serviceClient.getDestination(request, authHeader, (error, response) => {
        if (error) {
          return obs.error(error);
        }
        obs.next(response.toObject());
        obs.complete();
      });
    });
  }

  function updateDestination$({
    id,
    name,
    referencePoint,
    imageUrl,
    geonameId
  }: Destination.AsObject) {
    return new Observable<void>(obs => {
      const request = new Destination();

      request.setId(id);
      request.setName(name);

      if (referencePoint) {
        const l = new LatLong();
        l.setLat(referencePoint.lat);
        l.setLon(referencePoint.lon);
        request.setReferencePoint(l);
      }

      request.setImageUrl(imageUrl);
      request.setGeonameId(geonameId);

      serviceClient.updateDestination(request, authHeader, error => {
        if (error) {
          return obs.error(error);
        }
        obs.next();
        return obs.complete();
      });
    });
  }

  function deleteDestination$({ id }: DestinationRef.AsObject) {
    return new Observable<void>(obs => {
      const request = new DestinationRef();
      request.setId(id);

      serviceClient.deleteDestination(request, authHeader, error => {
        if (error) {
          return obs.error(error);
        }
        obs.next();
        return obs.complete();
      });
    });
  }

  return {
    findDestinations$,
    getAllDestinations$,
    getDestination$,
    createDestination$,
    updateDestination$,
    deleteDestination$
  };
};
//#endregion

export default useRepository;

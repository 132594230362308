import React, { useEffect } from "react";

import LocationDataTable from "./LocationDataTable";
import Layout from "./Layout";
import LayoutState from "@/modules/app/LayoutState";
import { LocationsState } from "@/modules/location";
import { Navigate, Outlet, Route } from "react-router-dom";

const RootView = () => {
  const { setPageTitle } = LayoutState.useContainer();
  useEffect(() => {
    setPageTitle("GeoLocation Search");
  }, []);
  return (
    <LocationsState.Provider>
      <Layout>
        <Outlet />
      </Layout>
    </LocationsState.Provider>
  );
};

const LocationRoutes = (
  <Route path="location" element={<RootView />}>
    <Route
      path="/location"
      element={<Navigate to={"/location/list"} replace />}
    />
    <Route path="list" element={<LocationDataTable />} />
  </Route>
);

export default LocationRoutes;

import React from "react";
import { GeoLocation } from "@/modules/location/types";
import { Destination } from "@travello/destination-service/js/destination_service_pb";
import DataObjectParser from "dataobject-parser";
import { DestinationsState } from "@/modules/destination";
import { Button } from "@material/react-button";
import MaterialIcon from "@material/react-material-icon";
import { useNavigate } from "react-router-dom";

const CreateDestinationTemplate: React.FC<GeoLocation> = ({
  name,
  geoName: { id: geonameId, latitude: lat, longitude: lon }
}) => {
  const navigate = useNavigate();
  const { destinationsAll } = DestinationsState.useContainer();

  if (destinationsAll.some(v => geonameId === v.geonameId)) {
    return null;
  }

  const params: Partial<Destination.AsObject> = {
    name,
    geonameId,
    referencePoint: {
      lat,
      lon
    }
  };

  const searchParams = new URLSearchParams(
    DataObjectParser.untranspose(params)
  );

  return (
    <Button
      raised
      dense
      icon={<MaterialIcon icon="add" />}
      onClick={e => {
        navigate({
          pathname: "/destination/create",
          search: searchParams.toString()
        });
      }}
    >
      Destination
    </Button>
  );
};

export default CreateDestinationTemplate;

import React from "react";
import classes from "./DataTableLinkTemplate.module.scss";

const DataTableLinkTemplate: React.FC<{ href?: string; target?: string }> = ({
  href,
  target,
  children
}) => {
  if (href) {
    return (
      <a
        className={classes.link}
        href={href}
        target={target}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return null;
};

export default DataTableLinkTemplate;

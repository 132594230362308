import React from "react";
import { GeoLocation } from "@/modules/location/types";
import StructuredDetailView from "@/pages/shared/components/StructuredDetailView";

import classes from "./LocationGeoDetail.module.scss";

const LocationGeoDetail: React.FC<GeoLocation> = ({ geoName }) => {
  return (
    <div className={classes.dataTable}>
      <StructuredDetailView {...geoName} />
    </div>
  );
};

export default LocationGeoDetail;

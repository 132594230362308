import React from "react";
import useConfig from "@/modules/app/useConfig";
import NotificationContent from "./NotificationContent";

const EnvironmentIndicator = () => {
  const { __APP_CONFIG__ } = useConfig();
  if (__APP_CONFIG__.bugsnag.releaseStage !== "production") {
    return (
      <NotificationContent
        message={
          <span>
            <strong>{__APP_CONFIG__.bugsnag.releaseStage}</strong> environment
          </span>
        }
        variant="warning"
      />
    );
  }
  return null;
};

export default EnvironmentIndicator;

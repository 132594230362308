import React from "react";

import SearchBox from "@/pages/shared/components/SearchBox";
import { LocationsState } from "@/modules/location";

import { Cell, Grid, Row } from "@material/react-layout-grid";

const Layout: React.FC = ({ children }) => {
  const {
    searchTerm,
    actions: { setSearchTerm }
  } = LocationsState.useContainer();

  return (
    <>
      <Grid>
        <Row>
          <Cell columns={4}>
            <SearchBox {...{ searchTerm, setSearchTerm }} />
          </Cell>
          <Cell columns={8}>
            <p>
              Type in a search for a geolocation you want to create a{" "}
              <strong>Destination</strong> for
            </p>
          </Cell>
        </Row>
      </Grid>
      {children}
    </>
  );
};

export default Layout;

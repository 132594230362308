import useRepository from "./useRepository";
import { grpcDataBuffer } from "@/modules/utils/observable/operators";

import model from "./model";
import { useStore } from "../app/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { ProductReview } from "@travello/livn-service/js/product_service_pb";
import { concatMap, tap } from "rxjs/operators";
import { of } from "rxjs";
import { useWait } from "../shared/useWait";

const useReviews = () => {
  const store = useStore();
  store.register(model);
  const dispatch = useDispatch();
  const { startWaiting, endWaiting, isWaiting } = useWait();

  const { fetchReviews$, updateReview$ } = useRepository();

  const LOADING_MESSAGE = "fetching reviews";

  const fetchPendingReviews = () => {
    startWaiting(LOADING_MESSAGE);
    const reviews$ = fetchReviews$({}).pipe(grpcDataBuffer());
    return reviews$
      .pipe(
        concatMap((value, index) =>
          index === 0
            ? of(value).pipe(tap(() => dispatch(model.actions.clearReviews()))) // fire on first value
            : of(value)
        )
      )
      .subscribe(
        value => {
          if (value.length === 0) {
            return;
          }
          dispatch(model.actions.pushReviews(value));
        },
        null,
        () => {
          endWaiting(LOADING_MESSAGE);
        }
      );
  };

  const reload = () => {
    return fetchPendingReviews();
  };

  const setApproval = async (id: number, approve: boolean) => {
    const { userId, productId, review } = model.selectors.getReviewById(id)(
      store.getState()
    );

    const newStatus = approve
      ? ProductReview.Status.APPROVED
      : ProductReview.Status.REJECTED;

    dispatch(model.actions.approveReview({ id, payload: newStatus }));

    try {
      await updateReview$({
        userId,
        productId,
        review: {
          ...review!,
          status: newStatus
        }
      }).toPromise();
      reload();
    } catch (e) {
      // roll back for api errors
      dispatch(
        model.actions.approveReview({
          id,
          payload: ProductReview.Status.PENDING
        })
      );
    }
  };

  return {
    state: {
      isLoading: isWaiting(LOADING_MESSAGE),
      reviews: useSelector(model.selectors.getReviews)
    },
    actions: {
      loadReviews: reload,
      setApproval
    }
  };
};

export default useReviews;

// mostly from here https://github.com/TeamWertarbyte/material-ui-chip-input/blob/master/stories/examples/react-autosuggest.js
// converted to hook syntax

import React, { useState, useMemo } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Autosuggest, {
  RenderInputComponent,
  RenderSuggestion,
  RenderSuggestionsContainer
} from "react-autosuggest";
import ChipInput, { Props as ChipInputProps } from "material-ui-chip-input";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { MenuItem, Paper } from "@material-ui/core";
import { useList } from "react-use";
import { useProduct } from "@/modules/product";
import { xor } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      position: "relative"
    },
    suggestionsContainerOpen: {
      position: "absolute",
      marginTop: theme.spacing(),
      marginBottom: theme.spacing() * 3,
      left: 0,
      right: 0,
      zIndex: 1
    },
    suggestion: {
      display: "block"
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: "none"
    },
    textField: {
      width: "100%"
    }
  })
);

const renderInput: RenderInputComponent<string> = ({
  value,
  onChange,
  chips,
  ref,
  defaultValue,
  ...other
}: any) => {
  return (
    <ChipInput
      InputProps={{ autoComplete: "off" }}
      defaultValue={defaultValue as any}
      clearInputValueOnChange
      onUpdateInput={onChange as any}
      value={chips}
      inputRef={ref}
      {...other}
    />
  );
};

const renderSuggestion: RenderSuggestion<string> = (
  suggestion,
  { query, isHighlighted }
) => {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={(e: any) => e.preventDefault()} // prevent the click causing the input to be blurred
    >
      <>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>{part.text}</span>
          );
        })}
      </>
    </MenuItem>
  );
};

const renderSuggestionsContainer: RenderSuggestionsContainer = ({
  containerProps,
  children
}) => {
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
};

const getSuggestionValue = (suggestion: string) => {
  return suggestion;
};

const filterSuggestions = (
  value: string,
  suggestions: string[],
  selected: any[] = []
) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : xor<string>(suggestions, selected).filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion.toLowerCase().slice(0, inputLength) === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
};

interface Props extends ChipInputProps {
  productId: string;
}

const LabelsAutoSuggest: React.FC<Props> = ({
  value,
  onAdd,
  onDelete,
  productId,
  ...rest
}) => {
  const classes = useStyles({});

  const [textFieldInput, setTextFieldInput] = useState<string>("");
  const [suggestions, { set: setSuggestions }] = useList<string>([]);

  const filteredSuggestions = useMemo(() => {
    return filterSuggestions(textFieldInput, suggestions, value);
  }, [textFieldInput, suggestions, value]);

  const {
    actions: { fetchAllLabels }
  } = useProduct(productId);

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSuggestionsFetchRequested = () => {
    fetchAllLabels().then(setSuggestions);
  };

  return (
    <Autosuggest
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion
      }}
      renderInputComponent={renderInput}
      suggestions={filteredSuggestions}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      renderSuggestionsContainer={renderSuggestionsContainer}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={(e, { suggestionValue }) => {
        onAdd && onAdd(suggestionValue);
        setTextFieldInput("");
        e.preventDefault();
      }}
      focusInputOnSuggestionClick
      inputProps={
        {
          ...rest,
          chips: value,
          onChange: (event: any, { newValue }: any) => {
            setTextFieldInput(newValue);
          },
          value: textFieldInput,
          onAdd,
          onDelete
        } as any
      }
    />
  );
};

export default LabelsAutoSuggest;

import React, { useRef, memo, useEffect } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import ReviewApprovalActionTemplate from "./components/ReviewApprovalActionTemplate";
import { useEffectOnce, useList } from "react-use";
import { useReviews } from "@/modules/review";
import DataTableLinkTemplate from "../shared/components/DataTableLinkTemplate";
import BusyIndicator from "../shared/components/BusyIndicator";

import { ProductReviewExtended } from "@travello/livn-service/js/product_service_pb";
import useConfig from "@/modules/app/useConfig";
import { get } from "lodash";
import { vsprintf } from "sprintf-js";
import useAuth from "@/modules/auth/useAuth";

const ReviewsPage: React.FC = memo(() => {
  const {
    state: { reviews: _reviews, isLoading },
    actions: { loadReviews }
  } = useReviews();

  const [reviews, { set: setReviews }] = useList(_reviews);

  const dtRef = useRef<any>();

  useEffectOnce(() => {
    loadReviews();
  });

  useEffect(() => {
    if (!isLoading) {
      setReviews(_reviews);
    }
  }, [isLoading, _reviews]);

  const { __APP_CONFIG__ } = useConfig();
  const userUrlTemplate = get(__APP_CONFIG__, ["links", "user"], "%s");
  const { isIFrame } = useAuth();

  const renderApprovalAction = (p: ProductReviewExtended.AsObject) => {
    return <ReviewApprovalActionTemplate {...p} />;
  };

  const renderUserId = ({ userId }: ProductReviewExtended.AsObject) => {
    const href = vsprintf(userUrlTemplate, [encodeURI(userId)]);
    return (
      <DataTableLinkTemplate
        href={href}
        target={isIFrame ? "_parent" : "dashboard-user"}
      >
        {userId}
      </DataTableLinkTemplate>
    );
  };

  const renderCreatedTime = ({
    createdTime: { seconds, nanos } = { seconds: 0, nanos: 0 }
  }: ProductReviewExtended.AsObject) => {
    return <>{new Date(seconds * 1000 + nanos).toLocaleString()}</>;
  };

  if (isLoading && reviews.length === 0) {
    return <BusyIndicator />;
  }

  return (
    <DataTable
      ref={dtRef}
      value={reviews}
      sortMode="single"
      paginator
      rows={20}
      rowsPerPageOptions={[20, 50, 100]}
    >
      <Column
        style={{ width: "100px", paddingLeft: 0, paddingRight: 0 }}
        key="reviewApproval"
        header=""
        body={renderApprovalAction}
      />
      <Column
        style={{ width: "10%" }}
        key="createdTime"
        header="Created"
        field="createdTime.seconds"
        sortable
        body={renderCreatedTime}
      />
      <Column
        key="userId"
        field="userId"
        header="User ID"
        style={{ width: "15%" }}
        sortable
        filter
        filterMatchMode="contains"
        body={renderUserId}
      />
      <Column
        key="productId"
        field="productId"
        header="Product"
        style={{ width: "15%" }}
        sortable
        filter
        filterMatchMode="contains"
      />
      <Column
        key="rating"
        field="review.rating"
        header="Rating"
        sortable
        style={{ width: "10%" }}
      />
      <Column
        key="review_title"
        field="review.reviewTitle"
        header="Review Title"
        style={{ width: "30%" }}
        sortable
        filter
      />
      <Column
        key="review_title"
        field="review.reviewText"
        header="Review Text"
        style={{ width: "50%" }}
        sortable
        filter
      />
    </DataTable>
  );
});

export default ReviewsPage;

import React, { Fragment } from "react";

import { Destination } from "@travello/destination-service/js/destination_service_pb";
import useForm from "react-hook-form";
import Button from "@material/react-button";

import * as yup from "yup";

import classes from "./DestinationDetailForm.module.scss";
import { TextField } from "@rmwc/textfield";

import { Cell, Grid, Row } from "@material/react-layout-grid";

import DetailView from "@/pages/shared/components/StructuredDetailView";
import { OnSubmit } from "react-hook-form/dist/types";

import { CollapsibleList, SimpleListItem } from "@rmwc/list";

interface FormModel extends Destination.AsObject {}

const ValidationSchema = yup
  .object()
  .shape<Pick<FormModel, "imageUrl" | "name">>({
    imageUrl: yup.string(),
    name: yup
      .string()
      .min(2)
      .required("Required")
  });

export type DestinationDetailFormOnSubmit = (v: FormModel) => Promise<void>;

interface Props extends Destination.AsObject {
  onSubmit: DestinationDetailFormOnSubmit;
  submitButtonLabel?: string;
  ContentContainer?: React.ComponentType<any>;
  FooterContainer?: React.ComponentType<any>;
  disableButtonOnClean?: boolean;
}

const DestinationDetailForm: React.FC<Props> = ({
  children,
  onSubmit,
  submitButtonLabel,
  ContentContainer = Fragment,
  FooterContainer = Fragment,
  disableButtonOnClean,
  ...props
}) => {
  const {
    handleSubmit,
    register,
    errors,
    formState: { isSubmitting, dirty },
    watch
  } = useForm<FormModel>({
    validationSchema: ValidationSchema,
    defaultValues: props
  });

  const values = watch();

  const onFormSubmit: OnSubmit<FormModel> = (data, e) => {
    return onSubmit({ ...props, ...data });
  };

  return (
    <>
      <ContentContainer>
        <form>
          <Grid className={classes.layoutGrid}>
            <Row>
              <Cell columns={12}>
                <TextField
                  invalid={!!errors.name}
                  helpText={{
                    validationMsg: true,
                    children: errors.name?.message
                  }}
                  name="name"
                  label="Name"
                  outlined
                  className={classes.fullWidth}
                  inputRef={register}
                  type="text"
                />
              </Cell>
              <Cell columns={9}>
                <TextField
                  invalid={!!errors.imageUrl}
                  helpText={{
                    validationMsg: true,
                    children: errors.imageUrl?.message
                  }}
                  name="imageUrl"
                  label="Image"
                  outlined
                  className={classes.fullWidth}
                  inputRef={register}
                  type="text"
                />
              </Cell>
              <Cell columns={3} className={classes.textAlignRight}>
                <Button
                  dense
                  target="photo-search"
                  href={`https://unsplash.com/search/photos/${encodeURI(
                    values.name
                  )}`}
                >
                  Unsplash
                </Button>
                <br />
                <Button
                  dense
                  target="photo-search"
                  href={`https://www.pexels.com/search/${encodeURI(
                    values.name
                  )}`}
                >
                  Pexels
                </Button>
                <br />
                <Button
                  dense
                  target="photo-search"
                  href={`https://www.flickr.com/search/?license=7%2C9%2C10&advanced=1&text=${encodeURI(
                    values.name
                  )}`}
                >
                  Flickr
                </Button>
              </Cell>
              <Cell columns={12}>
                {!values.imageUrl ? null : (
                  <>
                    <span>Preview:</span>
                    <img
                      alt={values.imageUrl}
                      className={classes.image}
                      src={values.imageUrl}
                    />
                  </>
                )}
              </Cell>
            </Row>
          </Grid>
        </form>
        <CollapsibleList
          handle={
            <SimpleListItem
              graphic="info_outline"
              text="More Info"
              metaIcon="chevron_right"
            />
          }
        >
          <DetailView {...props} />
        </CollapsibleList>
      </ContentContainer>
      <FooterContainer>
        <Button
          className="mdc-dialog__button"
          raised
          type="submit"
          disabled={(!dirty && disableButtonOnClean) || isSubmitting}
          onClick={handleSubmit(onFormSubmit)}
        >
          {submitButtonLabel}
        </Button>
        {children}
      </FooterContainer>
    </>
  );
};

export default DestinationDetailForm;

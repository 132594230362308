// import { useEffect, useMemo, useState } from "react";

import { useOidc, useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { Metadata } from "grpc-web";
import { useMemo } from "react";

const useAuth = () => {
  const { accessToken } = useOidcAccessToken();
  const { logout } = useOidc();
  const { oidcUser } = useOidcUser();

  const authHeader = useMemo(() => {
    if (accessToken) {
      return { Authorization: `Bearer ${accessToken}` } as Metadata;
    }
  }, [accessToken]);

  return {
    user: oidcUser,
    isIFrame: globalThis.self !== globalThis.top,
    authHeader,
    logout
  };
};

export default useAuth;

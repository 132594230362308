import { useState } from "react";
import { useDebounce } from "react-use";
// https://usehooks.com/useDebounce/

function useDebouncedValue<T>(value: T, delay: number = 16) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delay,
    [value]
  );

  return debouncedValue;
}

export default useDebouncedValue;

import DataObjectParser from "dataobject-parser";
import { useEffectOnce } from "react-use";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useStore } from "../app/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createContainer } from "unstated-next";
import { isEqual } from "lodash";
import { useSearchParams } from "react-router-dom";
//#region state shape
export interface State {
  center?: [number, number];
}
//#endregion

const initialState: State = {};

export const model = createSlice({
  initialState,
  name: "center",
  reducers: {
    setCenter: (
      draft,
      action: PayloadAction<{ center?: [number, number] }>
    ) => {
      const { center } = action.payload;
      draft.center =
        center && ([Number(center[0]), Number(center[1])] as [number, number]);
    }
  }
});

const getSlicedState = (state: any) => state[model.name] as State;

//#region hook
const useMapCenter = () => {
  const store = useStore();
  store.register(model);
  const dispatch = useDispatch();
  const state = useSelector(getSlicedState, isEqual);

  const [params, setParams] = useSearchParams();
  const q = DataObjectParser.transpose<State>(
    Object.fromEntries(params)
  ).data();

  useEffectOnce(() => {
    if (!state.center && q.center) {
      dispatch(model.actions.setCenter(q));
    }
  });

  useEffect(() => {
    if (state.center) {
      setParams(DataObjectParser.untranspose(state));
    }
  }, [state.center]);

  const setCenter = (center?: [number, number]) => {
    dispatch(model.actions.setCenter({ center }));
  };

  return {
    state,
    actions: {
      setCenter
    }
  };
};
//#endregion

export const MapCenterContext = createContainer(useMapCenter);

MapCenterContext.Provider.displayName = "MapCenterContext";

import React from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { DestinationsState } from "@/modules/destination";

import { Destination } from "@travello/destination-service/js/destination_service_pb";
import SearchBox from "./SearchBox";

import { Cell, Grid, Row } from "@material/react-layout-grid";
import { useNavigate } from "react-router-dom";

const DestinationsDataTable: React.FC = () => {
  const navigate = useNavigate();

  const { destinations } = DestinationsState.useContainer();

  return (
    <>
      <Grid>
        <Row>
          <Cell columns={4}>
            <SearchBox />
          </Cell>
        </Row>
      </Grid>
      <DataTable
        value={destinations}
        sortMode="single"
        paginator
        rows={20}
        rowsPerPageOptions={[20, 50, 100]}
        selectionMode="single"
        onSelectionChange={({ value }) => {
          const v = value as Destination.AsObject;
          navigate(`/destination/list/${v.id}`);
        }}
        sortField="name"
        sortOrder={1}
      >
        <Column
          key="name"
          field="name"
          header="Name"
          style={{ width: "20%" }}
          sortable
          filter
          filterMatchMode="contains"
        />
        <Column
          key="geonameId"
          field="geonameId"
          header="GeoNameId"
          style={{ width: "20%" }}
          sortable
          filter
          filterType="number"
        />
        <Column key="imageUrl" field="imageUrl" header="ImageUrl" sortable />
      </DataTable>
    </>
  );
};

export default DestinationsDataTable;

// ripped off from https://redux.js.org/recipes/code-splitting#reducer-injection-approaches

import { combineReducers, Action, AnyAction } from "redux";

import {
  configureStore as _configureStore,
  ConfigureStoreOptions,
  EnhancedStore,
  Slice
} from "@reduxjs/toolkit";
import { useStore as _useStore } from "react-redux";

interface InjectableStore<S = any, A extends Action = AnyAction>
  extends EnhancedStore<S, A> {
  register(slice: Slice): void;
}

// Configure the store
export default function configureStore<S = any, A extends Action = AnyAction>(
  options: ConfigureStoreOptions<S, A>
) {
  const store = _configureStore(options) as any;

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  store.register = (slice: Slice) => {
    if (store.asyncReducers[slice.name]) {
      return;
    }
    store.asyncReducers[slice.name] = slice.reducer;
    store.replaceReducer(combineReducers(store.asyncReducers));
  };

  // Return the modified store
  return store as InjectableStore<S, A>;
}

export const useStore = <S = any, A extends Action = AnyAction>() =>
  _useStore() as InjectableStore<S, A>;

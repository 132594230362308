import React, { useEffect } from "react";
import useConfig from "../app/useConfig";
import { OidcProvider, useOidc } from "@axa-fr/react-oidc";

export const AuthGuard: React.FC = ({ children }) => {
  const { login, isAuthenticated } = useOidc();

  useEffect(() => {
    if (!isAuthenticated) {
      login("/destination/map");
    }
  }, []);

  return <>{isAuthenticated ? children : null}</>;
};

const AuthProvider: React.FC = ({ children }) => {
  const { __APP_CONFIG__ } = useConfig();

  return (
    <OidcProvider
      configuration={{
        client_id: __APP_CONFIG__.oidc.clientId,
        redirect_uri: window.location.origin + "/authentication/callback",
        silent_redirect_uri:
          window.location.origin + "/authentication/silent-callback",
        scope: "openid profile email offline_access", // offline_access scope allow your client to retrieve the refresh_token
        authority: __APP_CONFIG__.oidc.issuer,
        service_worker_only: false
      }}
    >
      {children}
    </OidcProvider>
  );
};

export default AuthProvider;
